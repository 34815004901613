<template>
    <q-select
        v-if="RoleEntity.RoleID"
        dense
        square
        filled
        :options="UserList"
        label="用户编码/姓名/手机号"
        v-model="selectedUser"
        option-value="UserID"
        option-label="UserName"
        map-options
        emit-value
    >
        <template v-slot:after>
            <q-btn
                flat
                dense
                icon="add_circle"
                class="text-primary"
                @click="onAddUser"
                >添加用户
                <q-tooltip>添加用户</q-tooltip>
            </q-btn>
        </template>
    </q-select>
    <div
        :style="{
            'max-height': $store.getters.maxPageHeight - (RoleEntity.RoleID?168:128) + 'px',
            'height': $store.getters.maxPageHeight - (RoleEntity.RoleID?168:128) + 'px',
            'overflow-y': 'auto',
        }"
    >
        <q-list class="shadow-2">
            <div v-for="d in RoleUserList" :key="d.UserID">
                <q-item clickable active-class="bg-grey-12" v-if="d.UserInfo">
                    <q-item-section avatar>
                        <q-avatar size="sm" color="primary" text-color="white">
                            {{
                                d.UserInfo
                                    ? d.UserInfo.UserName.substr(0, 1)
                                    : ""
                            }}
                        </q-avatar>
                    </q-item-section>

                    <q-item-section>
                        <q-item-label class="text-subtitle2">
                            <span
                                >{{ d.UserInfo.UserName }}
                                <small class="q-ml-lg text-grey">{{
                                    d.UserInfo.Sex
                                }}</small>
                            </span>
                        </q-item-label>
                        <q-item-label caption class="text-subtitle2">
                            <span class="q-mr-md"
                                ><q-icon class="q-mr-sm" name="contact_phone" />
                                {{ d.UserInfo.Phone }}
                            </span>
                            <span
                                ><q-icon
                                    name="contact_mail"
                                    class="q-mr-sm"
                                />{{ d.UserInfo.Email }}
                            </span>
                        </q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <div>
                            <q-btn
                                flat
                                icon="remove_circle"
                                color="red"
                                @click="onRemoveUser(d)"
                            >
                                <q-tooltip>移除用户</q-tooltip>
                            </q-btn>
                        </div>
                    </q-item-section>
                </q-item>
                <q-separator />
            </div>
        </q-list>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    computed: {
        ...mapState("SysRole", ["RoleEntity"]),
        ...mapState("SysRoleUser", ["Loading", "RoleUserList"]),
        ...mapState("SysUser", ["UserList"]),
    },
    watch: {
        RoleEntity: {
            handler(val) {
                if (val.RoleID) {
                    this.onLoadUserList();
                    this.onLoadSysUserList();
                }
            },
            immediate: true,
            deep: true,
        },
    },
    data() {
        return {
            selectedUser: null,
        };
    },
    methods: {
        ...mapActions("SysRoleUser", [
            "actLoadUserList",
            "actRemoveUser",
            "actAddUser",
        ]),
        onLoadUserList() {
            this.actLoadUserList({ RoleID: this.RoleEntity.RoleID })
                .then((res) => {
                    if (res.Code != 0) this.msg.error(res.Message || res);
                })
                .catch((res) => {
                    this.msg.error(res.Message || res);
                });
        },
        onRemoveUser(e) {
            this.actRemoveUser(e);
        },
        onAddUser() {
            if (this.selectedUser >= 0) {
                this.actAddUser({
                    UserID: this.selectedUser,
                    RoleID: this.RoleEntity.RoleID,
                }).then(() => {
                    this.selectedUser = null;
                });
            } else this.msg.info("请选择要添加的用户");
        },
        onLoadSysUserList() {
            this.$store.dispatch("SysUser/actLoadData", {
                HospID: this.$store.getters["appUser/hospID"],
            });
        },
    },
};
</script>
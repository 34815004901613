<template>
    <q-dialog v-model="showDlg" persistent>
        <q-card style="width: 500px; max-width: 60vw">
            <q-bar class="bg-primary text-white">
                <q-icon name="account_box" />
                <div>
                    角色信息<small class="q-ml-sm text-size-sm">{{
                        RoleInfo.UserID
                    }}</small>
                </div>

                <q-space />

                <q-btn dense flat icon="close" v-close-popup>
                    <q-tooltip>关闭</q-tooltip>
                </q-btn>
            </q-bar>

            <q-card-section class="q-pt-none">
                <q-form>
                    <q-input label="角色名称" v-model="RoleInfo.RoleName">
                        <template v-slot:after> </template>
                    </q-input>
                    <q-select
                    label="是否启用"
                        v-model="RoleInfo.IsValid"
                        :options="[
                            { value: true, label: '启用' },
                            { value: false, label: '禁用' },
                        ]"
                        map-options
                        emit-value
                    ></q-select>
                </q-form>
            </q-card-section>

            <q-card-actions align="right" class="bg-white text-teal">
                <q-btn
                    flat
                    label="确定"
                    :loading="Loading"
                    @click="onUpdateRoleInfo"
                />
                <q-btn flat label="取消" :loading="Loading" v-close-popup />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>


<script>
import { mapActions, mapState } from "vuex";
export default {
    computed: {
        ...mapState("SysRole", ["RoleEntity", "ShowDlg", "Loading"]),
        showDlg: {
            get() {
                return this.ShowDlg;
            },
            set(val) {
                console.log(val);
                this.$store.commit("SysRole/setShowDlg", val);
            },
        },
    },
    watch: {
        showDlg(val) {
            if (val) {
                Object.assign(this.RoleInfo, this.RoleEntity);
            } else {
                this.RoleInfo = {};
            }
        },
    },
    data() {
        return {
            RoleInfo: {},
        };
    },
    methods: {
        ...mapActions("SysRole", ["actUpdateRoleEntity"]),
        onUpdateRoleInfo() {
            this.actUpdateRoleEntity(this.RoleInfo).then((res) => {
                if (res.Code != 0) this.msg.error(res.Message);
            });
        },
    },
};
</script>
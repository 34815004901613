<template>
    <q-card flat>
        <q-item>
            <q-item-section avatar>
                <q-avatar>
                    <q-icon
                        class="glossy"
                        color="primary"
                        size="30px"
                        name="supervisor_account"
                    />
                </q-avatar>
            </q-item-section>

            <q-item-section>
                <q-item-label>角色设置</q-item-label>
                <q-item-label caption> 管理维护系统角色功能设置</q-item-label>
            </q-item-section>
            <q-item-section side> </q-item-section>
        </q-item>
        <q-separator />
        <q-card-section horizontal>
            <q-card-section class="col-3">
                <q-bar class="bg-secondary text-white shadow-1">
                    <div>
                        <q-icon name="supervisor_account" />
                        角色
                    </div>
                    <q-space />
                    <q-btn
                        dense
                        flat
                        icon="note_add"
                        class="text-white"
                        @click="actCreateRoleEntity"
                        >新增角色
                        <q-tooltip>新增角色</q-tooltip>
                    </q-btn>
                </q-bar>
                <div
                    class="border-1"
                    :style="{
                        'max-height': $store.getters.maxPageHeight - 128 + 'px',
                        'height': $store.getters.maxPageHeight - 128 + 'px',
                        'overflow-y': 'auto',
                    }"
                >
                    <RoleList />
                </div>
            </q-card-section>

            <q-card-section class="col-4">
                <q-bar class="bg-secondary text-white shadow-1">
                    <div>
                        <q-icon name="perm_identity" />
                        角色用户
                    </div>
                </q-bar>
                <div class="border-1">
                    <RoleUserList />
                </div>
            </q-card-section>

            <q-card-section class="col-5">
                <q-bar class="bg-secondary text-white shadow-1">
                    <div>
                        <q-icon name="widgets" />
                        角色权限
                    </div>
                    <q-space />
                    <q-btn
                        dense
                        flat
                        icon="save"
                        class="text-white"
                        @click="onUpdateRoleModules"
                    >
                        <q-tooltip>保存功能权限</q-tooltip>
                    </q-btn>
                </q-bar>
                <div
                    class="border-1"
                    :style="{
                        'max-height': $store.getters.maxPageHeight - 128 + 'px',
                        'height': $store.getters.maxPageHeight - 128 + 'px',
                        'overflow-y': 'auto',
                    }"
                >
                    <RoleModules />
                </div>
            </q-card-section>
        </q-card-section>
        <RoleEdit />
    </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RoleEdit from "./roleEdit";
import RoleList from "./roleList";
import RoleUserList from "./roleUserList";
import RoleModules from "./roleModuleList";
export default {
    components: {
        RoleEdit,
        RoleList,
        RoleUserList,
        RoleModules,
    },
    computed: {
        ...mapState("SysRole", ["RoleEntity"]),
    },
    mounted() {
        this.onLoadData();
    },
    methods: {
        ...mapActions("SysRole", ["actLoadData", "actCreateRoleEntity"]),
        ...mapActions("SysRoleModules", ["actUpdateRoleModules"]),
        onLoadData() {
            this.actLoadData({})
                .then((res) => {
                    if (res.Code != 0) this.msg.error(res.Message || res);
                })
                .catch((res) => {
                    this.msg.error(res.Message || res);
                });
        },
        onUpdateRoleModules() {
            if (this.RoleEntity.RoleID) {
                this.actUpdateRoleModules(this.RoleEntity.RoleID);
            }
        },
    },
};
</script>

<style scoped>
.border-1 {
    border: 1px solid rgb(224, 223, 223);
}
</style>
<template>
    <q-list>
        <div v-for="d in RoleList" :key="d.RoleID">
            <q-item
                clickable
                :active="RoleEntity ? d.RoleID == RoleEntity.RoleID : false"
                active-class="bg-grey-12"
                @click="actSetRoleEntity(d)"
            >
                <q-item-section avatar>
                    <q-avatar size="sm" color="grey" text-color="white">
                        {{ d.RoleName ? d.RoleName.substr(0, 1) : "" }}
                    </q-avatar>
                </q-item-section>

                <q-item-section>
                    <q-item-label class="text-subtitle2">{{
                        d.RoleName
                    }}</q-item-label>
                </q-item-section>
                <q-item-section side>
                    <div>
                        <q-btn
                            flat
                            icon="edit"
                            color="primary"
                            @click.stop="actEditRoleEntity(d)"
                        >
                            <q-tooltip>修改角色信息</q-tooltip>
                        </q-btn>
                        <q-btn
                            flat
                            icon="delete"
                            color="red"
                            @click.stop="onDeleteRoleEntity(d)"
                        >
                            <q-tooltip>删除角色</q-tooltip>
                        </q-btn>
                    </div>
                </q-item-section>
            </q-item>
            <q-separator />
        </div>
    </q-list>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    computed: {
        ...mapState("SysRole", [
            "Loading",
            "ShowDlg",
            "RoleList",
            "RoleEntity",
        ]),
    },
    mounted() {
        this.onLoadData();
    },
    methods: {
        ...mapActions("SysRole", [
            "actLoadData",
            "actSetRoleEntity",
            "actEditRoleEntity",
            "actDeleteRoleEntity",
            "actCreateRoleEntity",
        ]),

        onLoadData() {
            this.actLoadData({})
                .then((res) => {
                    if (res.Code != 0) this.msg.error(res.Message || res);
                })
                .catch((res) => {
                    this.msg.error(res.Message || res);
                });
        },
        onDeleteRoleEntity(e) {
            this.dlg.confirm("是否确定要删除当前角色设置?").onOk(() => {
                this.actDeleteRoleEntity(e).then((res) => {
                    if (res.Code != 0) this.msg.error(res.Message);
                });
            });
        },
    },
};
</script>